.timeline-demo {
    .p-timeline-event-content,
    .p-timeline-event-opposite {
        line-height: 1;
    }

    @media screen and (max-width: 960px) {
        .customized-timeline {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;

                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
    }
}
